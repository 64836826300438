@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.project-container {
  background-color: #fff; /* Default background */
  transition: background-color 0.3s; /* Smooth transition for background color */
  cursor: default;
}

.project-container:hover {
  background-color: #141414; /* Background color on hover */
}

.project-container:hover svg {
  fill: #fff;
}

.project-container:hover .project-image {
  filter: brightness(0) invert(1) contrast(100);
}

.project-container:hover .view-project-button {
  display: flex; /* Show button on hover */
}

@keyframes flipUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes flipDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chevron-flip-up {
  animation: flipUp 0.5s forwards;
}

.chevron-flip-down {
  animation: flipDown 0.5s forwards;
}


.landing-button {
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -ms-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.landing-circle-overlay {
  z-index: 49; 
  background: white;
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0s linear 0.5s;
}

#circle-overlay-container:hover .landing-circle-overlay {
  display: flex;
}

/* When parent is hovered, grow the circle with the pulse animation */
#circle-overlay-container:hover .landing-circle-overlay {
  animation: pulseGrow 0.75s forwards;
}

/* When not hovered, shrink the circle back */
#circle-overlay-container:not(:hover) .landing-circle-overlay {
  animation: pulseShrink 0.25s forwards;
}

.landing-button:hover {
  color: #505050;
}

.project-container .view-project-button {
  display: none;
}

.project-container .project-title,
.project-container .project-subtitle {
  color: #000; /* Default text color */
}

.project-container:hover .project-title,
.project-container:hover .project-subtitle,
.project-container:hover .project-index {
  color: #fff; /* Text color on hover */
}

@keyframes scaleUp {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}

@keyframes drawLine {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes bobbing {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulseGrow {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulseShrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

